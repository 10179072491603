/*===============================================
	 * 現在スクロール位置によるグローバルナビのアクティブ表示
================================================*/
$(function () {
  var scrollMenu = function () {
    var array = {
      "#recommend01": 0,
      "#recommend02": 0,
      "#recommend03": 0,
      "#recommend04": 0,
      "#recommend05": 0,
      "#recommend06": 0,
      "#recommend07": 0,
      "#recommend08": 0,
      "#recommend09": 0,
      "#recommend10": 0,
      "#recommend11": 0,
      "#recommend12": 0,
    };

    var $globalNavi = new Array();

    for (var key in array) {
      if ($(key).offset()) {
        array[key] = $(key).offset().top - 200;
        $globalNavi[key] = $(
          '.top-recommended-contents-navi__list-item a[href="' + key + '"]'
        );
        //console.log($globalNavi[key]);
      }
    }

    $(window).scroll(function () {
      if (!$("body").hasClass("js-fullScreenMenu-open")) {
        // console.log("aaa")
        for (var key in array) {
          if ($(window).scrollTop() > array[key]) {
            $(".top-recommended-contents-navi__list li a").each(function () {
              $(this).removeClass("-current");
            });
            $globalNavi[key].addClass("-current");
          }
        }
      }
    });
  };

  // 実行
  scrollMenu();
});

/*===============================================
	 * スクロールマジックで表示
================================================*/

const $section = document.querySelectorAll(".js-scrollSection");
let width;
let imageHeight = $(".p-contens-image").height();

if (window.innerWidth >= 1600) {
  width = 1.6;
} else if (window.innerWidth < 1600 && window.innerWidth >= 1025) {
  width = 1.6;
} else if (window.innerWidth < 1025) {
  width = 1;
}

let controller = new ScrollMagic.Controller();
for (let i = 0; i < $section.length; i++) {
  let scene = new ScrollMagic.Scene({
    triggerElement: $section[i],
    triggerHook: "onEnter",
    reverse: false,
    offset: 200,
  }).addTo(controller);
  scene.on("enter", () => {
    //console.log('enter');
    $section[i].classList.add("is-view");
    $($section[i])
      .find(".p-contens-image-bg")
      .css({
        clip:
          "rect(0px " +
          $(".p-contens").width() * width +
          "px " +
          imageHeight +
          "px 0px)",
      });
    $($section[i])
      .find(".p-contens-image-cover")
      .css({
        clip:
          "rect(0px " +
          $(".p-contens").width() * width +
          "px " +
          imageHeight +
          "px 0px)",
      });
  });
  scene.on("end", () => {
    //console.log('end');
    scene.destroy(true);
  });
}
